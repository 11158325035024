:root {
  --primary-color: #001335;

  /* Background Colors */
  --bg-color-1: #0e1627;
  --bg-color-2: #e9ecee;
  --bg-color-3: rgba(0, 19, 53, 0.05);
  --bg-color-4: #192a49;
  --bg-color-5: rgba(242, 243, 245, 0.5);
  --bg-color-6: #97ffba;
  --bg-color-7: #3c52ef;
  --bg-color-8: rgba(0, 19, 53, 0.75);
  --bg-color-9: #fafafa;
  --bg-color-10: #ffffff;
  --bg-color-11: #e9ecee96;
  --bg-color-12: #001335;
  --bg-color-13: #ffbd39;
  --bg-color-14: #f2f3f5;
  --bg-color-15: #f3f2f5;
  --bg-color-16: #e1e5fe;
  --bg-color-17: #001335;
  --bg-color-18: #bebebe;
  --bg-color-19: #f9f9f9;
  --bg-color-20: #efefef;
  --bg-color-21: #c4c4c4;
  --bg-color-22: #bcb8b8;
  --bg-color-23: #f0f0f0;
  --bg-color-24: #f8f8f8;
  --bg-color-25: #f1c655;
  --bg-color-26: #253184;
  --bg-color-27: rgba(60, 82, 239, 0.83);
  --bg-color-28: rgba(255, 255, 255, 0.5);
  --bg-color-29: #f7f8f9;
  --bg-color-30: #001435;
  --bg-color-31: #cecece;
  --bg-color-32: #fbdfdb;
  --bg-color-33: #f2f2f2;
  --bg-color-34: #f4f4f4;
  --bg-color-35: #e3e3e3;
  --bg-color-36: rgba(242, 243, 245, 0.5);
  --bg-color-37: #f8f9fa;
  --bg-color-38: #fdfdfd;
  --bg-color-39: rgba(175, 175, 175, 0.5);
  --bg-color-40: #f4f5fc;
  --bg-color-41: #f25700;
  --bg-color-42: #344563;
  --bg-color-43: #4151e6;
  --bg-color-44: rgba(255, 189, 57, 0.3);
  --bg-color-45: #727b8c;
  --bg-color-46: rgba(235, 87, 87, 0.1);
  --bg-color-47: rgba(0, 19, 53, 0.1);
  --bg-color-48: #f0f1fd;
  --bg-color-49: #dfe1fb;
  --bg-color-50: #f2f6fb;
  --bg-color-51: #e6e7eb;
  --bg-color-52: rgba(226, 229, 252, 0.35);
  --bg-color-53: #f2f3fd;
  --bg-color-54: #ebebec;
  --bg-color-55: #f0f7ff;
  --bg-color-56: rgba(217, 217, 217, 0.25);
  --bg-color-57: #939cff;
  --bg-color-58: #daebff;
  --bg-color-59: #f8f8ff;
  --bg-color-60: #8fb4ff;
  --bg-color-61: #f2f3ff;
  --bg-color-62: #fff2d7;
  --bg-color-63: #e2e5fc59;
  --bg-color-64: #eef0ff;
  --bg-color-65: #cfcfcf;
  --bg-color-66: #dcdcdc;
  --bg-color-67: #f9fcfe;
  --bg-color-68: #eef5fb;
  --bg-color-69: #061331;
  --bg-color-70: #4051e6;
  --bg-color-71: #f5c055;
  --bg-color-72: #f3f4ff;
  --bg-color-73: #e3f0ff;
  --bg-color-74: #f9f9ff;
  --bg-color-75: #8eb4ff;
  --bg-color-76: #eff6ff;
  --bg-color-timelineV2: #f5faff;
  --bg-color-twoFactorMethod: #f7f7f7;
  --bg-color-patient-card-field: #e4e4e4;

  /* Text Colors */
  --text-color-1: #ffffff;
  --text-color-2: rgba(0, 19, 53, 0.5);
  --text-color-3: #3c52ef;
  --text-color-4: #c4c4c4;
  --text-color-5: rgba(0, 19, 53, 0.25);
  --text-color-6: #001335;
  --text-color-7: #000000;
  --text-color-8: #3c52ef;
  --text-color-9: rgba(255, 255, 255, 0.5);
  --text-color-10: #ffbd39;
  --text-color-11: #727b8c;
  --text-color-12: #abb0b8;
  --text-color-13: #8a8a8a;
  --text-color-14: #898484;
  --text-color-15: #252934;
  --text-color-16: #2134bf;
  --text-color-17: #eb5757;
  --text-color-18: #727b8c;
  --text-color-19: #b3b3b3;
  --text-color-20: #2f3654;
  --text-color-21: #a6aab3;
  --text-color-22: #ced0d4;
  --text-color-23: #5c6c88;
  --text-color-24: #4f6182;
  --text-color-25: #4151e6;
  --text-color-26: #212121;
  --text-color-26: #858b9c;
  --text-color-27: #041333;
  --text-color-28: #002d72;
  --text-color-29: #a7a9ba;
  --text-color-29: #626793;
  --text-color-30: #676f81;
  --text-color-31: 'rgba(4, 19, 51, 1)';
  --text-color-32: 'rgba(98, 103, 147, 1)';
  --text-color-33: #57b63f;
  --text-color-34: #031432;
  --text-color-35: #8297c7;
  --text-color-35: #041433;
  --text-color-36: #26c36f;
  --text-color-37: #747b89;
  --text-color-38: #acb0b8;
  --text-color-39: #97ffba;
  --text-color-40: rgba(175, 175, 175, 0.5);
  --text-color-41: #ebebec;
  --text-color-42: #8297c7;
  --text-color-43: #282828;
  --text-color-44: #747b8a;
  --text-color-45: #202046;
  --text-color-46: #636363;
  --text-color-47: #b8c8ff;
  --text-color-48: #4451de;
  --text-color-49: #f2f5ff;
  --text-color-50: #5b6270;
  --text-color-51: #3c51ee;
  --text-color-52: #888ea0;
  --text-color-53: #a0a6b1;
  --text-color-54: #3d52ef;
  --text-color-55: #8a8686;
  --text-color-56: #4f657e;
  --text-color-57: #818181;
  --text-color-58: #061331;
  --text-color-59: #abb2be;
  --text-color-60: rgba(114, 123, 140, 0.7);
  --text-color-61: #8caad2;
  --text-color-62: rgba(114, 123, 140, 1);
  --text-color-miniTimeline-date: #527195;

  /* Border Colors */
  --border-color-1: #3c52ef;
  --border-color-2: rgba(0, 19, 53, 0.5);
  --border-color-3: rgba(209, 209, 209, 0.5);
  --border-color-4: #ffffff;
  --border-color-5: #ffbd39;
  --border-color-6: #001335;
  --border-color-7: #ced0d4;
  --border-color-8: #c2c2c2;
  --border-color-9: #727272;
  --border-color-10: #ededed;
  --border-color-11: #bdbdbd;
  --border-color-12: #cbcbcb;
  --border-color-13: #727b8c;
  --border-color-14: #dddddd;
  --border-color-15: #97ffba;
  --border-color-16: #f1c40f;
  --border-color-17: #eb5757;
  --border-color-18: rgba(0, 19, 53, 0.07);
  --border-color-19: #d8d8d8;
  --border-color-20: #f1f1f1;
  --border-color-21: #a6aab3;
  --border-color-22: #2f3bac;
  --border-color-23: #b8bee9;
  --border-color-24: #acb0b7;
  --border-color-25: #ebebec;
  --border-color-26: #e1e2e3;
  --border-color-27: #000000;
  --border-color-28: #abb0b8;
  --border-color-29: #7f899a;
  --border-color-30: #4451de;
  --border-color-31: #b8c8ff;
  --border-color-32: #e5e7ea;
  --border-color-33: #dcdcdc;
  --border-color-34: #3e52f0;
  --border-color-35: #e6e6e6;
  --border-color-36: #727b8b;
  --border-color-37: #c4d6ee;
  --border-color-38: #dbeaff;

  /* Board Card Colors */
  --board-card-color-1: #3c52ef;
  --board-card-color-2: #ffbd39;
  --board-card-color-3: rgba(0, 19, 53, 0.5);
  --board-card-color-4: #97ffba;
  --board-card-color-5: #001335;

  --gi-color: #7f5ff9;

  --patient-header-height: 84px;
  --patient-banner-height: 45px;
  --autochart-chart-height: 45vh;
  --patients-list-min-width: 380px;
  --patients-list-max-width: 500px;
  --patients-side-bar-collapsed-width: 52px;

  --notification-center-unread-bgcolor: rgba(238, 240, 255, 1);
  --notification-center-read-bgcolor: rgba(250, 250, 250, 1);
}

html,
body,
#root {
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1000px) {
  html,
  body {
    overflow-x: hidden;
  }

  #root {
    min-width: 320px;
  }

  body {
    min-width: 320px;
    position: relative;
  }
}

html.mobile {
  height: auto;
  height: 100vh;
}

html.rc-mobile {
  overflow: 'auto !important';
  height: 'auto';
}

html.rc-mobile body {
  height: auto;
}

html.rc-mobile body #root {
  height: auto;
}

html.referral-mobile {
  overflow: 'auto !important';
  height: 'auto';
}

html.referral-mobile body {
  height: auto;
}

html.referral-mobile body #root {
  height: auto;
}

html.mobile body #root {
  height: 100%;
  overflow: hidden;
}

/* Chrome */
html.mobile-chrome {
  height: auto;
}

html.mobile-chrome body {
  height: 92.7vh;
}

html.mobile-chrome body #root {
  height: 100%;
  overflow: hidden;
}

/* Safari */
html.mobile-safari {
  height: auto;
}

html.mobile-safari body {
  height: 85vh;
}

html.mobile-safari body #root {
  height: 100%;
  overflow: hidden;
}

/* Chrome IOS */
html.mobile-chrome-ios {
  height: auto;
}

html.mobile-chrome-ios body {
  height: 88vh;
}

html.mobile-chrome-ios body #root {
  height: 100%;
  overflow: hidden;
}

.zoom-MuiPopper-root {
  z-index: 999999 !important;
}
